<template>
  <SettingsMain />
</template>

<script>
import SettingsMain from '@/components/settings/SettingsMain';

export default {
  name: 'Settings',
  components: {
    SettingsMain
  }
}
</script>

<style>

</style>