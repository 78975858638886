<template>
  <section class="settings">
    <div class="settings__list">
      <div v-for="setting of settings" :key="setting.name" :class="{active: selectedDirectory == setting.component}" @click="selectedDirectory = setting.component">{{ setting.name }}</div>
    </div>
    <component :is="selectedDirectory"></component>
  </section>
</template>

<script>
import DifficultyMain from './setting/DifficultyMain';
import ScoreMain from './setting/ScoreMain';
import SpeedMain from './setting/SpeedMain';
import HelpMain from './setting/HelpMain';

export default {
  name: 'DirectoryMain',
  data() {
    return {
      selectedDirectory: DifficultyMain,
      settings: [
        { name: 'Уровни сложности', component: DifficultyMain },
        { name: 'Постоянные настройки', component: ScoreMain },
        { name: 'Характеристики скорости', component: SpeedMain },
        { name: 'Справка', component: HelpMain },
      ],
    }
  },
}
</script>

<style lang="scss" scoped>
.settings {
  flex-grow: 1;
  padding: 25px;
  overflow: auto;
  &__list {
    display: flex;
    margin-bottom: 30px;
    > div {
      color: #00325C;
      font-weight: 500;
      font-size: 20px;
      cursor: pointer;
      padding: 5px 12px 15px 12px;
      &:not(:last-child) {
        margin-right: 5px;
      }
      &:hover {
        color: #054b85;
        transition: 0.3s all;
      }
      &.active {
        box-shadow: inset 0 -3px 0 #6461F6;
      }
    }
  }
  &__container {
    display: grid;
    gap: 15px;
    grid-template-columns: repeat(7, 1fr);
    grid-template-rows: repeat(4, 1fr);
    
  }
  .setting {
    display: flex;
    flex-direction: column;
  }
}
</style>