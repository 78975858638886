<template>
  <div class="help__container">
    <div class="general">
      <div class="input__block">
        <span>Цель игры</span>
        <textarea class="input" v-model="help.goal" type="text" placeholder="Цель игры"></textarea>
      </div>
      <div class="input__block">
        <span>Способ перемещения</span>
        <textarea class="input" v-model="help.way" type="text" placeholder="Способ перемещения"></textarea>
      </div>
      <div class="input__block">
        <span>Подсказки</span>
        <textarea class="input" v-model="help.hints" type="text" placeholder="Подсказки"></textarea>
      </div>
      <div class="input__block">
        <span>Начисление баллов</span>
        <textarea class="input" v-model="help.balls" type="text" placeholder="Начисление баллов"></textarea>
      </div>
      <div class="actions">
        <label>
          <div>
            <svg width="19" height="20" viewBox="0 0 19 20" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M18.5516 7.28394C18.2253 6.95936 17.6976 6.96081 17.3731 7.28711L7.35535 17.359C6.05567 18.6587 3.94481 18.6587 2.64271 17.3572C1.34159 16.0555 1.34159 13.9446 2.64287 12.6433L12.9571 2.27489C13.7685 1.46351 15.0877 1.46351 15.9015 2.27665C16.715 3.09018 16.715 4.40923 15.9013 5.223L7.35691 13.7673C7.35637 13.7679 7.3559 13.7685 7.35535 13.769C7.02983 14.0928 6.50377 14.0923 6.17892 13.7675C5.85352 13.4421 5.85352 12.9147 6.17892 12.5893L10.3032 8.46412C10.6286 8.13865 10.6286 7.61098 10.3031 7.28558C9.97763 6.96019 9.44996 6.96022 9.12457 7.2857L5.0003 11.4108C4.02407 12.387 4.02407 13.9697 5.00038 14.946C5.97665 15.9222 7.55934 15.9222 8.53565 14.946C8.53678 14.9448 8.53768 14.9436 8.53877 14.9425L17.0798 6.40146C18.5445 4.93682 18.5445 2.56248 17.0798 1.09784C15.615 -0.365945 13.2408 -0.365945 11.777 1.09784L1.46277 11.4663C-0.487823 13.4169 -0.487823 16.5828 1.46417 18.5358C3.41745 20.488 6.58338 20.488 8.53549 18.5359L18.5548 8.46248C18.8793 8.13615 18.8779 7.60852 18.5516 7.28394Z" fill="#9E9DBB"/>
            </svg>
            <span>Прикрепить</span>
            <span v-if="help.video && !newvideo">Файл выбран</span>
            <span v-if="newvideo">Новый файл выбран</span>
          </div>
          <input @change="uploadVideo" type="file" name="video" ref="video">
        </label>
        <button class="btn btn-main" @click="saveHelp">Сохранить</button>
        <button v-if="!s_help" @click="create">Help create</button>
      </div>
    </div>
  </div>
</template>

<script>
import Axios from 'axios';


export default {
  name: 'HelpMain',
  data() {
    return {
      help: {
        way: '',
        hints: '',
        goal: '',
        balls: '',
      },
      newvideo: false,
      s_help: true
    }
  },
  mounted() {
    Axios
      .get(`/api/help`, {
        headers: {
          token: this.$store.getters.token
        }
      })
      .then(res => {
        this.help = res.data;
      })
      .catch(err => {
        this.s_help = false;
        console.error(err.response);
      });
  },
  methods: {
    uploadVideo() {
      this.video = this.$refs.video.files[0];
      if (this.video) {
        if (!this.video.type.includes('mp4') && !this.video.type.includes('mov')) {
          this.video = null;
          this.$refs.video.value = '';
          return this.$bus.$emit('showNotification', {message: 'Загрузить можно только файлы с расширением mp4 или mov!', status: 'danger'});
        } else {
          this.newvideo = true;
        }
      } else {
        this.video = null;
        $(this.$refs.video).value('');
        return this.$bus.$emit('showNotification', {message: 'Во время загрузки файла произошла ошибка!', status: 'danger'});
      }
    },
    create() {
      Axios
        .post(`/api/help`, {}, {
          headers: {
            token: this.$store.getters.token
          }
        })
        .then(res => {
          this.s_help = true;
        })
    },
    saveHelp() {
      Axios
        .put(`/api/help`, this.help, {
          headers: {
            token: this.$store.getters.token
          }
        })
        .then(res => {
          this.$bus.$emit('showNotification', {status: 'success', message: 'Данные успешно сохранены!'});
        })
        .catch(err => {
          console.error(err.response);
        });
      this.saveVideo();
    },
    saveVideo() {
      const formData = new FormData();
      formData.append('video', this.video);
      Axios
        .post(`/api/help/video`, formData, {
          headers: {
            token: this.$store.getters.token,
            'Content-Type': 'multipart/form-data'
          }
        })
        .then(res => {
          this.$bus.$emit('showNotification', {status: 'success', message: 'Видео успешно сохранено!'});
        })
        .catch(err => {
          console.error(err.response);
        });
    }
  }
}
</script>

<style lang="scss" scoped>
.help__container {
  height: 100%;
  display: grid;
  gap: 15px;
  grid-template-columns: repeat(4, 1fr);
  grid-template-rows: repeat(4, 1fr);
  .general {
    padding: 15px 25px;
    grid-area: 1 / 1 / 1 / 3;
    textarea {
      height: 140px !important;
    }
    .input__block:not(:last-child) {
      margin-bottom: 10px;
    }
    .actions {
      label {
        color: #00325C;
        font-size: 14px;
        cursor: pointer;
        div { 
          margin-top: 10px;
          display: flex;
          align-items: center;
          span {
            margin-left: 10px;
          }
        }
      }
      display: flex;
      justify-content: space-between;
    }
  }
}
</style>