<template>
  <div class="directories__container">
    <div class="score__container general">
      <CommonTitle :options="options" />
      <CommonTable :options="options" />
    </div>
    <div class="small ads general">
      <div class="title">Реклама</div>
      <div class="container">
        <div>Длина последовательности уровней без рекламы</div>
        <input type="text" v-if="game_settings" v-model="game_settings.length_between_ads" @change="changeGameSettings">
      </div>
    </div>
    <div class="small inaction general">
      <div class="title">Бездействие</div>
      <div class="container">
        <div>Количество секунд бездействия для отмены подсказки</div>
        <input type="text" v-if="game_settings" v-model="game_settings.inaction_time" @change="changeGameSettings">
      </div>
    </div>
  </div>
</template>

<script>
import Axios from 'axios';
import CommonTitle from '../../common/CommonTitle';
import CommonTable from '../../common/CommonTable';

export default {
  name: 'ScoreMain',
  data() {
    return {
      game_settings: null,
      options: {actions: 'edit', styles: 'grid-area: 1 / 3 / 3 / 6', id: 'score', request: 'scores', name: 'Баллы', table: {
        variables: ['description', 'score'],
        page: 1,
        limit: 4,
        total: 0,
        data: []
      }, ths: [
        {id: 'description', name: 'За', value: '', sort: true, sort_data: '', search: true, width: '50%'},
        {id: 'score', name: 'Баллы', value: '', sort: true, sort_data: '', search: true, width: '30%'},
        {id: '', name: '', width: '20%'},
      ]},
    }
  },
  mounted() {
    Axios
      .get(`/api/scores`, {
        headers: {
          token: this.$store.getters.token
        }
      })
      .then(res => {
        this.options.table.data = res.data.data;
        this.options.table.page = 1;
        this.options.table.limit = 12;
        this.options.table.total = res.data.total;
      })
      .catch(err => {
        console.error(err.response);
      })

    Axios
      .get(`/api/game/settings`, {
        headers: {
          token: this.$store.getters.token
        }
      })
      .then(res => {
        this.game_settings = res.data;
      })
      .catch(err => {
        console.error(err.response);
      })

    this.$bus.$on('createSetting', (result) => {
      this.options.table.data.push(result.data);
    });
    this.$bus.$on('editSetting', (result) => {
      const setting = this.options.table.data.find(el => el._id === result.data._id);
      if (setting) {
        if (setting.name) setting.name = result.data.name;
        if (setting.description) setting.description = result.data.description;
        if (setting.score) setting.score = result.data.score;
        if (setting.coefficient) setting.coefficient = result.data.coefficient;
        if (setting.min_time) setting.min_time = result.data.min_time;
        if (setting.max_time) setting.max_time = result.data.max_time;
      }
    });
    this.$bus.$on('removeSetting', (result) => {
      this.options.table.data = this.options.table.data.filter(el => el._id !== result.data);
    });
  },
  methods: {
    changeGameSettings() {
      Axios
        .put(`/api/game/settings`, this.game_settings, {
          headers: {
            token: this.$store.getters.token
          }
        })
        .then(res => {
          
        })
        .catch(err => {
          console.error(err.response);
        })
    },
  },
  components: {
    CommonTitle,
    CommonTable
  }
}
</script>

<style lang="scss" scoped>
.directories__container {
  display: grid;
  gap: 15px;
  grid-template-columns: repeat(4, 1fr);
  grid-template-rows: repeat(4, 1fr);
  .score__container {
    grid-area: 1 / 1 / 5 / 3;
  }
  .ads {
    grid-area: 1 / 3 / 3 / 4;
  }
  .inaction {
    grid-area: 3 / 3 / 5 / 4;
  }
}
.small {
  padding-top: 15px;
  text-align: center;
  color: #00325C;
  font-weight: 500;
  width: 250px;
  .container {
    padding-bottom: 10px;
    > div {
      font-size: 9px;
      margin: 10px 20px;
      height: 35px;
      line-height: 1.2;
      margin-bottom: 5px;
      text-transform: uppercase;
    }
    input {
      width: 60%;
      text-align: center;
    }
  }
}
</style>