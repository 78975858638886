<template>
  <div class="directories__container general">
    <CommonTitle :options="options" />
    <CommonTable :options="options" />
  </div>
</template>

<script>
import Axios from 'axios';
import CommonTitle from '../../common/CommonTitle';
import CommonTable from '../../common/CommonTable';

export default {
  name: 'DifficultyMain',
  data() {
    return {
      options: {add: 'Добавить уровень', actions: 'edit-remove-add', styles: 'grid-area: 1 / 1 / 5 / 3', id: 'difficulty', request: 'difficulties', name: 'Уровни сложности', table: {
        variables: ['name', 'coefficient'],
        page: 0,
        limit: 10,
        total: 0,
        data: []
      }, ths: [
        {id: 'name', name: 'Уровень', value: '', sort: true, sort_data: '', search: true, width: '35%'},
        {id: 'coefficient', name: 'Коэффициент', value: '', sort: true, sort_data: '', search: true, width: '45%'},
        {id: '', name: '', width: '20%'},
      ]},
    }
  },
  mounted() {
    Axios
      .get(`/api/difficulties`, {
        headers: {
          token: this.$store.getters.token
        },
        params: {
          limit: 10,
          page: 0
        }
      })
      .then(res => {
        this.options.table.data = res.data.data;
        this.options.table.page = 0;
        this.options.table.limit = 10;
        this.options.table.total = res.data.total;
      })
      .catch(err => {
        console.error(err.response);
      })

    this.$bus.$on('createSetting', (result) => {
      this.options.table.data.push(result.data);
    });
    this.$bus.$on('editSetting', (result) => {
      const setting = this.options.table.data.find(el => el._id === result.data._id);
      if (setting) {
        if (setting.name) setting.name = result.data.name;
        if (setting.description) setting.description = result.data.description;
        if (setting.score) setting.score = result.data.score;
        if (setting.coefficient) setting.coefficient = result.data.coefficient;
        if (setting.min_time) setting.min_time = result.data.min_time;
        if (setting.max_time) setting.max_time = result.data.max_time;
      }
    });
    this.$bus.$on('removeSetting', (result) => {
      this.options.table.data = this.options.table.data.filter(el => el._id !== result.data);
    });
  },
  components: {
    CommonTitle,
    CommonTable
  }
}
</script>

<style lang="scss" scoped>
.directories__container {
    width: 50%;
}
</style>